@font-face {
  font-family: "Neutra Text TF";
  src:
          /*local("Neutra Text TF"),*/
          url('../public/fonts/NeutraTextTF-Book.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Sana Sans Alt';
  src:
          /*local("SanaSansAlt-Regular"),*/
          url('../public/fonts/SanaSansAlt-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;

}

@font-face {
  font-family: 'Sana Sans Alt';
  src:
          /*local("SanaSansAlt-Medium"),*/
          url('../public/fonts/SanaSansAlt-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src:
          //local("SanaSansAlt-Bold"),
          url('../public/fonts/SanaSansAlt-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}


.unblur {
  animation: unblur 0.2s linear;
}

@keyframes unblur {
  from {
    filter: blur(20px);
  }
  to {
    filter: blur(0);
  }
}
